define("ucrm-client-signup-form/routes/signup/complete", ["exports", "ucrm-client-signup-form/config/environment"], function (exports, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return _environment.default.APP.completionText;
    }
  });
});