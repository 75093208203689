define('ucrm-client-signup-form/adapters/application', ['exports', 'ember-data', 'ember-ajax/mixins/ajax-support', 'ucrm-client-signup-form/config/environment'], function (exports, _emberData, _ajaxSupport, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONAPIAdapter.extend(_ajaxSupport.default, {
    host: _environment.default.APP.host,
    headers: Ember.computed('host', function () {
      return {
        "Content-Type": 'application/json'
      };
    }),
    pathForType: function pathForType() {
      return '';
    }
  });
});