define("ucrm-client-signup-form/models/client", ["exports", "ember-data", "ember-cp-validations"], function (exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr;


  var Validations = (0, _emberCpValidations.buildValidations)({
    firstName: [(0, _emberCpValidations.validator)("presence", true), (0, _emberCpValidations.validator)("length", {
      min: 3
    })],
    lastName: [(0, _emberCpValidations.validator)("presence", true), (0, _emberCpValidations.validator)("length", {
      min: 3
    })],
    email: [(0, _emberCpValidations.validator)("presence", true), (0, _emberCpValidations.validator)("format", { type: "email" })],
    phone: [(0, _emberCpValidations.validator)("presence", true)],
    street1: [(0, _emberCpValidations.validator)("presence", true)],
    city: [(0, _emberCpValidations.validator)("presence", true)],
    zipCode: [(0, _emberCpValidations.validator)("presence", true)],
    stateId: [(0, _emberCpValidations.validator)("presence", true)]
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    firstName: attr("string"),
    lastName: attr("string"),
    contacts: attr(),
    street1: attr("string"),
    street2: attr("string"),
    zipCode: attr("string"),
    city: attr("string"),
    email: attr("string"),
    phone: attr("string"),
    stateId: attr("number", { defaultValue: null }),
    countryId: attr("number", { defaultValue: null })
    // agreedToTAC: attr('boolean', {defaultValue: false}),
  });
});