define('ucrm-client-signup-form/routes/signup', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    model: function model() {
      return Ember.RSVP.hash({
        client: this.get('store').createRecord('client')
      });
    }
  });
});