define("ucrm-client-signup-form/components/custom-trigger", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "", // Prevents the component from adding an extra wrapping div

    selectedCountry: null,

    // Computed property to display the selected option's value safely
    selectedCountryDisplay: Ember.computed("selectedCountry", function () {
      return Ember.String.htmlSafe(this.selectedCountry ? this.selectedCountry.name : "");
    })
  });
});