define('ucrm-client-signup-form/helpers/is-value', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isValue = isValue;
  function isValue(params /*, hash*/) {
    return params[0] === params[1];
  }

  exports.default = Ember.Helper.helper(isValue);
});